import React, { useEffect } from 'react';
import { Pressable, View, ViewStyle } from 'react-native';
import { useLinkTo } from '~/navigation/router';

import {
  useNormalize,
  useTranslate,
  accent_color,
  Text,
} from '@unboared/base-ui.all';
import { useScreenInfos } from '@unboared/utils.orientation';

import { log } from '~/config/env';
import { useMargin, GoogleSignUp } from '~/screens/common/signin';
import { EmailPasswordSignUp } from './components/email_password';
import { useAuth } from '~/services/auth';
import { ActivityIndicator } from '~/components/common/loaders';
import { GuestSignIn } from '../signin/components/guest';
import { CentralSeparator, Separator } from '../separator';
import { useAnalyticsAccessEvent } from '~/services/firebase';

/**
 * Sign in page.
 */
export const SignUp = () => {
  log.debug('Mount SignUp');
  useAnalyticsAccessEvent('platform/signup');

  const { normalize } = useNormalize();
  const { isPortrait } = useScreenInfos();
  const loading = useAuth((state) => state.loading);

  const styles = {
    container: {
      flexDirection: 'row',
    } as ViewStyle,
    flexCenter: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    } as ViewStyle,
    content: {
      width: normalize(250),
      justifyContent: 'center',
    } as ViewStyle,
  };

  return (
    <View style={[styles.flexCenter, styles.container]}>
      <View style={styles.flexCenter}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View style={styles.content}>
            <EmailPasswordSignUp />
            <Separator />
            <GoogleSignUp />
            {isPortrait && (
              <>
                <Separator />
                <GuestSignIn />
              </>
            )}
            <CreateAccount />
          </View>
        )}
      </View>
      {isPortrait || (
        <>
          {loading || <CentralSeparator />}
          <View style={styles.flexCenter}>
            {loading || (
              <View style={styles.content}>
                <GuestSignIn />
              </View>
            )}
          </View>
        </>
      )}
    </View>
  );
};

const CreateAccount = () => {
  const { translate } = useTranslate();
  const { largeMargin } = useMargin();

  const linkTo = useLinkTo();
  const resetFields = useAuth((state) => state.resetFields);

  return (
    <Pressable
      onPress={() => {
        resetFields();
        linkTo('/login');
      }}
    >
      <Text
        style={{
          ...largeMargin,
          alignSelf: 'center',
          fontWeight:'bold'
        }}
      >
        {translate('common.signup.alreadyAccount.message')}{' '}
        <Text
          style={{
            color: accent_color.important,
          }}
          tx="common.signup.alreadyAccount.login"
        />
      </Text>
    </Pressable>
  );
};

export default SignUp;
