import React from 'react';
import { View } from 'react-native';

import { useCommunication } from '@unboared/lib';
import { PortraitMobileLayout } from '@unboared/base-ui.all';
import { useScreenInfos } from '@unboared/utils.orientation';

import { GamepadPortraitLayout } from '~/config/layouts';
import { ChangeScreenOrientationPage } from '~/screens/common/change_screen_orientation';
import { NavigationPad } from '~/components/gamepad/view/navigation_pad';

export const GlobalPopupNavigation = () => {
  const { emitAction } = useCommunication();

  const { isPortrait } = useScreenInfos();
  if (!isPortrait) {
    return <ChangeScreenOrientationPage to="portrait" />;
  }

  return (
    <PortraitMobileLayout
      flex={GamepadPortraitLayout.ClassicLayoutWithNav}
      header={<></>}
    >
      <View style={{ flex: 1, justifyContent: 'flex-end' }}>
        <NavigationPad
          pressOk={() => emitAction('global_popup_ok')}
          pressUp={() => emitAction('global_popup_up')}
          pressDown={() => emitAction('global_popup_down')}
          pressLeft={() => emitAction('global_popup_left')}
          pressRight={() => emitAction('global_popup_right')}
        />
      </View>
    </PortraitMobileLayout>
  );
};
