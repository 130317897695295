import React, { useEffect, useRef } from 'react';
import { View, Image, ScrollView } from 'react-native';

import { Heading, space, useNormalize } from '@unboared/base-ui.all';
import { GameCard } from '~/components/common/card/game_card';

import { GameCollectionProps } from '../game_collection';
import { useAuth } from '~/services/auth';
import { CardGameSkeleton } from '../game_list/game_list';

export const GameGrid = ({
  games,
  activeGame,
  size = 100,
  style,
  loading,
  onPress = (game: any) => console.log(game),
}: GameCollectionProps) => {
  const flatListRef = useRef<any>();
  const { normalize } = useNormalize();
  const normalizedSize = normalize(size);

  const gameStyles = {
    padding: 0.1 * normalizedSize,
    marginRight: 0.05 * normalizedSize,
  };

  if (loading) {
    return (
      <View style={{ flexDirection: 'row' }}>
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
      </View>
    );
  }

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={[
        { flexDirection: 'row', flexWrap: 'wrap' },
        style,
      ]}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      {games.map((game) => (
        <GameCard
          {...game}
          key={game.id}
          active={game.id == activeGame}
          size={size}
          style={gameStyles}
          onPress={() => onPress(game)}
        />
      ))}
    </ScrollView>
  );
};