import React from 'react';
import { Pressable } from 'react-native';

import {
  Heading,
  Icon,
  useNormalize,
  space,
  useTheme,
} from '@unboared/base-ui.all';
import { useFullscreen } from '@unboared/utils.fullscreen';

export const OpenFullscreenView = () => {
  const { normalize } = useNormalize();
  const theme = useTheme();
  const { toggleFullscreen } = useFullscreen();

  return (
    <Pressable
      style={{
        flex: 1,
        backgroundColor: theme.backgroundColor,
        padding: normalize(space.small),
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onPress={toggleFullscreen}
    >
      <Icon name="fa-hand-pointer" size={20} />
      <Heading
        style={{ textAlign: 'center' }}
        type="h3"
        tx="gamepad.platform.openFullscreenPage.infos"
      />
    </Pressable>
  );
};
