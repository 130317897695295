import { View } from 'react-native';
import { useCommunication } from '@unboared/lib';
import { NavGamepadButton } from './nav_gamepad_button';

const navbarUnboaredUI = [
  {
    short: 'games',
    tx: 'common.navigation.games',
    icon: 'gamepad',
    path: '/games',
    action: 'pressUnboaredGames',
  },
  {
    short: 'search',
    tx: 'common.navigation.search',
    icon: 'search',
    path: '/search',
    action: 'pressUnboaredSearch',
  },
  {
    short: 'settings',
    tx: 'common.navigation.settings',
    icon: 'settings',
    path: '/settings',
    action: 'pressUnboaredSettings',
  },
];

export const NavGamepad = ({
  style,
  activePage,
}: {
  style?: any;
  activePage?: string;
}) => {
  const { emitAction } = useCommunication();
  const access = (navItem: any) => {
    emitAction(navItem.action);
  };

  return (
    <View
      style={[
        { width: '100%', flexDirection: 'row', justifyContent: 'space-around' },
        style,
      ]}
    >
      {navbarUnboaredUI.map((item) => (
        <NavGamepadButton
          id={item.short}
          key={item.short}
          icon={item.icon}
          txTitle={item.tx}
          active={activePage === item.short}
          onPress={() => access(item)}
        />
      ))}
    </View>
  );
};
