import React, { useState } from 'react';
import { View } from 'react-native';

import {
  accent_color,
  Button,
  Heading,
  Icon,
  space,
  Text,
  useNormalize,
  useTranslate,
} from '@unboared/base-ui.all';
import { useGlobalPopup } from '~/components/screen/popup/global_popup';

import { useListener } from '@unboared/lib';

export type NotEnoughPlayersAlertProps = {
  size?: number;
  game: string;
  minPlayers: number;
};

export const NotEnoughPlayersAlert2 = ({
  size = 60,
  game,
  minPlayers,
}: NotEnoughPlayersAlertProps) => {
  const [currentButton, setCurrentButton] = useState<number>(0);
  const { normalize } = useNormalize();
  const { translate } = useTranslate();

  const close = useGlobalPopup((state) => state.close);

  useListener('global_popup_ok', () => {
    close();
  });

  return (
    <View style={{ justifyContent: 'center' }}>
      <Heading
        text={`✋ ${translate('screen.alerts.not_enough_players.title')}`}
      />
      <View
        style={{
          marginVertical: normalize(space.small),
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text>
          {translate('screen.alerts.not_enough_players.message1_1')}
          <Text style={{ fontWeight: '900' }} text={` ${game} `} />
          {translate('screen.alerts.not_enough_players.message1_2')}
          <Text
            style={{ color: accent_color.important, fontWeight: '900' }}
            text={` ${minPlayers} ${translate(
              'screen.alerts.not_enough_players.message1_3',
            )}`}
          />
        </Text>

        <Text>
          {`${translate('screen.alerts.not_enough_players.message2_1')} `}
          <Icon name="addPlayer" size={15} color={accent_color.important} />
          {` ${translate('screen.alerts.not_enough_players.message2_2')}`}
        </Text>
      </View>
      <View
        style={{
          alignSelf: 'stretch',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Button
          preset={!currentButton ? 'primary' : 'secondary'}
          tx="screen.alerts.not_available_game.close"
          onPress={close}
        />
      </View>
    </View>
  );
};
