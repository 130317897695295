import React from 'react';
import { View } from 'react-native';

import {
  usePlayers,
  useCommunication,
  useCustomDeviceStateProperty,
} from '@unboared/lib';

import {
  PortraitMobileLayout,
  size,
  space,
  Text,
  useTheme,
} from '@unboared/base-ui.all';
import { ConnexionButton, MuteButton } from '@unboared/design.all';

import { GamepadPortraitLayout } from '~/config/layouts';
import { WaitingView } from '~/components/gamepad/view/waiting_view';
import { HideMessage } from './hide_message';

export const ConnexionInfosGamepad = () => {
  const { isMaster } = usePlayers();
  if (isMaster()) {
    return <ConnexionInfosHideShow />;
  } else {
    return <WaitingView />;
  }
};

const ConnexionInfosHideShow = () => {
  const theme = useTheme();
  const { emitAction } = useCommunication();
  // Get unboared informations
  const { getCustomDeviceStateProperty } = useCustomDeviceStateProperty();
  const { getHostID } = usePlayers();

  // Setup UI variables
  const volumeValue = getCustomDeviceStateProperty(getHostID(), 'volume');
  const coValue = getCustomDeviceStateProperty(getHostID(), 'connexionInfos');

  const activeVolume = volumeValue !== undefined ? volumeValue == 0 : true;
  const activeConnect = coValue !== undefined ? coValue : true;

  return (
    <PortraitMobileLayout
      flex={GamepadPortraitLayout.ClassicLayoutWithNav}
      header={<HideMessage />}
    >
      <View
        style={{
          flex: 1,
          alignSelf: 'stretch',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-around',
        }}
      >
        <MuteButton
          size={30}
          onPress={() => emitAction('pressMuteButton')}
          active={activeVolume}
        />

        <ConnexionButton
          size={30}
          onPress={() => emitAction('pressConnexionInfos')}
          active={activeConnect}
        />
      </View>
    </PortraitMobileLayout>
  );
};
