import { Button, Heading, space, Text, useNormalize } from "@unboared/base-ui.all"
import { View } from "react-native"
import { useGlobalPopup } from "~/components/screen/popup/global_popup"
import { useAuth } from "~/services/auth"
import { ActivityIndicator } from "~/components/common/loaders"
import { Reauthenticate } from "./reauthenticate"

export const DeleteAccountConfirmation = () => {
    const { normalize } = useNormalize()

    const deleteAccount = useAuth(state => state.deleteAccount)
    const close = useGlobalPopup(state => state.close)
    const reauthenticating = useAuth(state => state.reauthenticating)
    const loading = useAuth(state => state.loading)

    if (loading) {
        return <ActivityIndicator />
    }

    // if (reauthenticating) {
        return <Reauthenticate txAction="screen.settingPage.myAccount.action.deleteAccount.title" onAuthenticated={deleteAccount} />
    // }

    // return (
    //     <>
    //         <Heading type='h2' tx="screen.settingPage.myAccount.action.deleteAccount.popup.title" />
    //         <Text style={{ marginTop: normalize(space.small) }} tx="screen.settingPage.myAccount.action.deleteAccount.popup.description" />
    //         <Text style={{ marginBottom: normalize(space.small) }} tx="screen.settingPage.myAccount.action.deleteAccount.popup.description2" />
    //         <View style={{ flexDirection: 'row' }}>
    //             <Button tx="screen.settingPage.myAccount.action.deleteAccount.popup.validate" onPress={deleteAccount} />
    //             <Button text="No" onPress={close} />
    //         </View>
    //     </>
    // )
}
