import React from 'react';
import { View } from 'react-native';
import {
  Icon,
  size,
  space,
  Text,
  transparency,
  useTheme,
} from '@unboared/base-ui.all';
import { ConnexionButton } from '@unboared/design.all';

export const HideMessage = () => {
  const theme = useTheme();
  return (
    <View
      style={{
        padding: space.medium,
        borderRadius: size.borderRadius.medium,
        backgroundColor: transparency(theme.mode),
      }}
    >
      <View>
        <Text>💡</Text>
      </View>
      <Text>
        <Text tx="gamepad.platform.connexionInfosPage.help1" />
        <Icon name="md-person-add-alt" color={theme.color.primary} />
        <Text tx="gamepad.platform.connexionInfosPage.help2" />
      </Text>
    </View>
  );
};
