import React, { useEffect } from 'react';
import {
  api,
  useListener,
  usePlayers,
  useCustomDeviceStateProperty,
} from '@unboared/lib';

import {
  MusicPlayer,
  useMusicManager as useMusicManagerBit,
} from '@unboared/utils.sound';

import { useTheme } from '@unboared/base-ui.all';
import { useLaunchSystem } from '~/components/screen/popup/launch_system';

export const useMusicListener = () => {
  const { getDeviceID } = usePlayers();
  const { loadCustomDeviceStateProperty } = useCustomDeviceStateProperty();
  const toggleMute = useMusicManager((state) => state.toggleMute);
  const volume = useMusicManager((state) => state.volume);
  const onVolumeDown = useMusicManager((state) => state.onVolumeDown);

  useEffect(()=>{
    onVolumeDown()
    onVolumeDown()
    onVolumeDown()
    onVolumeDown()
    onVolumeDown()
  }, [])

  useEffect(() => {
    loadCustomDeviceStateProperty(getDeviceID(), 'volume', volume);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volume]);

  useListener(
    'pressMuteButton',
    () => {
      toggleMute();
      api().mute(volume > 0);
    },
    [volume],
  );
};

export const Music = React.memo(() => {
  const theme = useTheme();
  const { launched } = useLaunchSystem();

  if (launched) {
    return (
      <MusicPlayer
        style={{ position: 'absolute' }}
        source={theme.song.main}
        blurred
        hidden
      />
    );
  }
  return null;
});

export const useMusicManager = useMusicManagerBit;
