import React, { useRef } from 'react';

import { useTranslate, FullPageOverlay } from '@unboared/base-ui.all';
import { useWebViewPlugin } from './useWebViewPlugin';
import { UnboaredActivityIndicator } from '@unboared/design.loaders';

export const GameRunner = ({ title, src }: { title: string; src: string }) => {
  console.log("[Game Runner] v0.3 ")
  const webviewRef = useRef(null);
  const { translate } = useTranslate();
  const { isReady } = useWebViewPlugin(webviewRef, src);

  return (
    <>
      <FullPageOverlay active={!isReady}>
        <UnboaredActivityIndicator size={60} infos={translate('screen.loading.gameIFrame')} />
      </FullPageOverlay>
      <iframe
        ref={webviewRef}
        title={title}
        src={src}
        width="100%"
        height="100%"
        allow="autoplay" 
        frameBorder="0"
      />
    </>
  );
};
