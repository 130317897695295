import React from 'react';
import BaseShimmerPlaceholder from 'react-native-shimmer-placeholder';
import LinearGradient from 'expo-linear-gradient';
import { size, useNormalize, useTheme } from '@unboared/base-ui.all';

export const ShimmerPlaceholder = (props: any) => {
  const { shimmerStyle, ...rest } = props;

  const theme = useTheme();
  const { normalize } = useNormalize();
  return (
    <BaseShimmerPlaceholder
      {...rest}
      LinearGradient={LinearGradient}
      shimmerColors={[
        theme.currentPalette.c800,
        theme.currentPalette.c300,
        theme.currentPalette.c800,
      ]}
      shimmerStyle={[
        {
          margin: 0,
          borderRadius: normalize(size.borderRadius.smaller),
        },
        shimmerStyle,
      ]}
    />
  );
};
