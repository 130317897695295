import { useEffect } from 'react';

import { BlurredOverlay } from '@unboared/base-ui.all';
import { api, useListener } from '@unboared/lib';

import { PauseMenu } from '~/components/screen/view/pause_menu';
import { usePauseMenu } from '~/utils/pause_menu';

export const PauseMenuPopup = ({ game }) => {
  const { showPauseMenu, toggleShowPauseMenu } = usePauseMenu();

  useListener(
    'pressPause',
    () => {
      toggleShowPauseMenu();
      if (showPauseMenu) {
        api().resume();
      } else {
        api().pause();
      }
    },
    [showPauseMenu],
  );

  return (
    <BlurredOverlay active={showPauseMenu}>
      <PauseMenu game={game} onClose={toggleShowPauseMenu} />
    </BlurredOverlay>
  );
};
