import React, { useEffect } from 'react';

import {
  usePlayers,
  useCustomDeviceStateProperty,
  useListener,
} from '@unboared/lib';

import {
  BlurredOverlay,
  Modal,
  space,
  Text,
  useNormalize,
} from '@unboared/base-ui.all';
import { useGlobalPopup } from './useGlobalPopup';
import { Pressable } from 'react-native';

export const GlobalPopup = () => {
  const content = useGlobalPopup((state) => state.content);
  const { normalize } = useNormalize();

  const { getDeviceID } = usePlayers();
  const { loadCustomDeviceStateProperty } = useCustomDeviceStateProperty();

  useEffect(() => {
    if (content !== undefined) {
      loadCustomDeviceStateProperty(getDeviceID(), 'globalPopup', true);
    } else {
      loadCustomDeviceStateProperty(getDeviceID(), 'globalPopup', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <BlurredOverlay active={content}>
      <Modal>
        <>
          <ClosePopup
            style={{
              position: 'absolute',
              right: -normalize(space.smaller),
              top: -normalize(space.smaller),
            }}
          />
          {content}
        </>
      </Modal>
    </BlurredOverlay>
  );
};

const ClosePopup = ({ style }: any) => {
  const close = useGlobalPopup((state) => state.close);
  useListener('pressClose', () => {
    close();
  });

  return (
    <Pressable style={style} onPress={close}>
      <Text preset="big" style={{ fontFamily: 'Inter', fontWeight:'bold' }} text="x" />
    </Pressable>
  );
};
