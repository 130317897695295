import React from 'react';
import {
  Button,
  Heading,
  space,
  Text,
  useNormalize,
} from '@unboared/base-ui.all';
import { Image, View } from 'react-native';

type OneTextTwoButtonsCenteredPopupLayoutProps = {
  ratio?: number;
  sizeImg: number;
  scale?: number;
  src?: any;
  txTitle: string;
  txDescription?: string;
  activeButton: number;
  txButton1?: string;
  txButton2?: string;
  onPressBtn1?: () => void;
  onPressBtn2?: () => void;
  children?: any;
};

export const OneTextTwoButtonsCenteredPopupLayout = (
  props: OneTextTwoButtonsCenteredPopupLayoutProps,
) => {
  const { normalize } = useNormalize();
  const {
    sizeImg,
    scale = 1,
    txTitle,
    txDescription,
    txButton1,
    txButton2,
    children,
    src,
    onPressBtn1,
    onPressBtn2,
    activeButton,
  } = props;

  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        transform: [{ scale: scale }],
      }}
    >
      {src && (
        <Image
          source={src}
          style={{
            width: normalize(sizeImg),
            height: normalize(sizeImg),
          }}
        />
      )}
      <Heading style={{ textAlign: 'center' }} tx={txTitle} />
      <View
        style={{
          marginVertical: normalize(space.small),
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children || <Text tx={txDescription} />}
      </View>
      <View
        style={{
          alignSelf: 'stretch',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Button
          preset={activeButton ? 'secondary' : 'primary'}
          tx={txButton1}
          onPress={onPressBtn1}
        />
        <Button
          preset={activeButton ? 'primary' : 'secondary'}
          tx={txButton2}
          onPress={onPressBtn2}
        />
      </View>
    </View>
  );
};
