import React, { useState } from 'react';
import { View } from 'react-native';

import {
  Button,
  Heading,
  space,
  Text,
  useNormalize,
  useTranslate,
} from '@unboared/base-ui.all';
import { useGlobalPopup } from '~/components/screen/popup/global_popup';

import { useListener } from '@unboared/lib';

export type ComingSoonAlertProps = {
  gameName: string;
  size?: number;
};

export const ComingSoonAlert = ({
  gameName,
  size = 60,
}: ComingSoonAlertProps) => {
  const [currentButton, setCurrentButton] = useState<number>(0);
  const { normalize } = useNormalize();
  const { translate } = useTranslate();

  const close = useGlobalPopup((state) => state.close);
  const great = () => {
    close();
  };

  useListener('global_popup_ok', () => {
    setCurrentButton((state) => {
      if (state) {
        close();
      } else {
        great();
      }
      return state;
    });
  });

  useListener('global_popup_right', () => {
    setCurrentButton(1);
  });

  useListener('global_popup_left', () => {
    setCurrentButton(0);
  });

  return (
    <View style={{ justifyContent: 'center' }}>
      <Heading
        text={
          '⌛ ' +
          gameName +
          ' ' +
          translate('screen.alerts.not_available_game.title')
        }
      />
      <View
        style={{
          marginVertical: normalize(space.small),
          // alignItems: 'center',
          // justifyContent: 'center',
        }}
      >
        <Text
          text={
            gameName +
            ' ' +
            translate('screen.alerts.not_available_game.message')
          }
        />
      </View>
      <View
        style={{
          alignSelf: 'stretch',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          style={{ marginRight: normalize(space.small) }}
          preset={currentButton ? 'secondary' : 'primary'}
          tx="screen.alerts.not_available_game.great"
          onPress={great}
        />
        <Button
          preset={currentButton ? 'primary' : 'secondary'}
          tx="screen.alerts.not_available_game.close"
          onPress={close}
        />
      </View>
    </View>
  );
};
