import React from 'react';
import styled from 'rn-css';
import { usePlayers, useCustomDeviceStateProperty } from '@unboared/lib';

import { transparency, useTheme } from '@unboared/base-ui.all';
import { VISUAL_CONNEXION_INFOS_GAMEPAD } from '~/config/const';
import { ConnexionInfosGamepad2 } from './connexion_infos2';
import { ConnexionInfosGamepad } from './connexion_infos';

export const ConditionalConnexionInfos = () => {
  const theme = useTheme();
  const { getHostID } = usePlayers();
  const { getCustomDeviceStateProperty } = useCustomDeviceStateProperty();

  const connexionInfos = getCustomDeviceStateProperty(
    getHostID(),
    'connexionInfos',
  );

  return (
    <>
      {connexionInfos && (
        <PauseOver
          backgroundColor={
            VISUAL_CONNEXION_INFOS_GAMEPAD
              ? transparency(theme.mode === 'dark' ? 'light' : 'dark', 0.6)
              : theme.backgroundColor
          }
        >
          {VISUAL_CONNEXION_INFOS_GAMEPAD ? (
            <ConnexionInfosGamepad2 />
          ) : (
            <ConnexionInfosGamepad />
          )}
        </PauseOver>
      )}
    </>
  );
};

const PauseOver = styled.View<{ backgroundColor: string }>`
  position: absolute;
  background-color: ${(props) => props.backgroundColor};
  top: 0;
  left: 0;
  z-index: 9;
  elevation: 9;
  width: 100%;
  height: 100%;
`;
