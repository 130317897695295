import { createGlobalStorage } from "@unboared/utils.storage";
import { SHOW_CONNEXION_INFOS_ON_OPEN } from "~/config/const";

interface ConnexionInfosState {
    showConnexionInfos: boolean;
    toggleShowConnexionInfos: () => void;
}

export const useConnexionStore = createGlobalStorage<ConnexionInfosState>(
    (set) => ({
        showConnexionInfos: SHOW_CONNEXION_INFOS_ON_OPEN,
        toggleShowConnexionInfos: () => set((state) => ({ showConnexionInfos: !state.showConnexionInfos }))
    })
)

export default function useConnexionInfos() {
    const { showConnexionInfos, toggleShowConnexionInfos } = useConnexionStore();

    return { showConnexionInfos, toggleShowConnexionInfos };
}