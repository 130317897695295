import { createGlobalStorage } from '@unboared/utils.storage'

/* The popup state type  */
export type LaunchSystemState = {
    interacted:boolean;
    launched: boolean;
    interact: () => void;
    uninteract: () => void;
    launch: () => void;
    unlaunch: () => void;
}

/* The initial storage state */
export const initialLaunchSystemState = {
    interacted:false,
    launched: false
}

/* The store that keep informations about the global popup */
export const useLaunchSystem = createGlobalStorage<LaunchSystemState>(
    (set: any, get: any) => ({
        ...initialLaunchSystemState,
        interact: () => {
            set((state: LaunchSystemState) => ({ ...state, interacted: true }))
        },
        uninteract: () => {
            set((state: LaunchSystemState) => ({ ...state, interacted: false }))
        },
        launch: () => {
            set((state: LaunchSystemState) => ({ ...state, launched: true }))
        },
        unlaunch: () => {
            set((state: LaunchSystemState) => ({ ...state, launched: false }))
        },
    })
)