import React, { useEffect, useState } from 'react';
import { Pressable } from 'react-native';
import { useLinkTo } from '~/navigation/router';

import { accent_color } from '@unboared/base-ui.theme.colors';

import {
  Button,
  Heading,
  Text,
  TextInput,
  useNormalize,
  space,
  useTranslate,
} from '@unboared/base-ui.all';
import { useMargin } from '../hooks/useMargin';
import { PasswordInput } from './PasswordInput';
import { useAuth } from '~/services/auth';
import { useScreenInfos } from '@unboared/utils.orientation';

export const EmailPasswordSignIn = () => {
  const { normalize } = useNormalize();
  const { translate } = useTranslate();
  const { smallMargin, mediumMargin, largeMargin } = useMargin();
  const { type } = useScreenInfos();

  const linkTo = useLinkTo();

  const {
    status,
    email,
    password,
    changeEmail,
    changePassword,
    emailErrorMessage,
    passwordErrorMessage,
    errorMessage,
    errorCode,
    login,
    onForgotPassword,
  } = useAuth();

  useEffect(() => {
    if (status === 'RESET_PASSWORD') {
      linkTo(`/auth/mail?mail=${email}`);
    }
  }, [email, status]);

  const onSubmit = () => login(email, password);

  return (
    <>
      {/* {errorCode  && (
        <Text
          style={[{ color: accent_color.warning }]}
          text={`error code [${errorCode}]`}
        />
      )} */}
      <Button onPress={() => linkTo('/login')} style={{ marginBottom:normalize(space.small), paddingLeft: normalize(15), maxWidth:normalize(30), justifyContent:'center' }} preset="secondary" icon="back" />
      <TextInput
        style={{ height: normalize(30) }}
        containerStyle={mediumMargin}
        text={email}
        onChangeText={changeEmail}
        textContentType="emailAddress"
        placeholder={translate(
          'common.signin.email_password.email.placeholder',
        )}
        returnKeyType="next"
        helperText={translate(emailErrorMessage)}
        warning={emailErrorMessage}
      />
      <PasswordInput
        password={password}
        onChangeValue={changePassword}
        onSubmitEditing={onSubmit}
        passwordErrorMessage={translate(passwordErrorMessage)}
      />
      <ForgotPasswordButton onPress={onForgotPassword} />
      <Button
        style={smallMargin}
        tx="common.signin.email_password.submitButton"
        onPress={onSubmit}
      />
    </>
  );
};

export const ForgotPasswordButton = ({ onPress }: { onPress: () => void }) => {
  const { largeMargin } = useMargin();

  return (
    <Pressable onPress={onPress}>
      <Text
        style={{
          ...largeMargin,
          color: accent_color.important,
          alignSelf: 'flex-end',
        }}
        tx="common.signin.email_password.forgotPassword.message"
      />
    </Pressable>
  );
};
