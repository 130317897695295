import React from 'react';
import { View, ScrollView } from 'react-native';

import {
  Text,
  Heading,
  PortraitMobileLayout,
  useTranslate,
  space,
  useNormalize,
} from '@unboared/base-ui.all';
import { ConnectedPlayers } from '@unboared/design.all';
import { usePlayers } from '@unboared/lib';

const WaitingMessage = ({ masterColor, masterName, ...props }: any) => {
  const { translate } = useTranslate();
  return (
    <View style={{ flex: 1, justifyContent: 'space-evenly' }} {...props}>
      <Heading>
        <Heading style={{ color: masterColor }} text={masterName} />
        {translate('gamepad.platform.waitingPage.infos')}
      </Heading>
      <Text>
        {translate('gamepad.platform.waitingPage.help', {
          master: masterName,
        })}
      </Text>
    </View>
  );
};

export const WaitingView = () => {
  const { getPlayers, getColor, getUID, getUsername, getMasterID } = usePlayers();
  const masterID = getMasterID();
  const { normalize } = useNormalize();

  return (
    <PortraitMobileLayout
      header={
        <WaitingMessage
          masterColor={getColor(masterID)}
          masterName={getUsername(masterID)}
        />
      }
      flex={{ header: 50, content: 50, footer: 0 }}
      contentStyle={{ alignItems: 'flex-start' }}
    >
      <Text
        tx="screen.gamesPage.connectedPlayers"
        preset="light"
        style={{ alignSelf: 'center' }}
      />
      <ConnectedPlayers
        style={{
          flex: 1,
          marginLeft: normalize(space.small),
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
        players={getPlayers()}
        masterID={getUID(masterID)}
        size={30}
      />
    </PortraitMobileLayout>
  );
};
