import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { TextInput, space, useNormalize } from '@unboared/base-ui.all';
import { useListener, useScene } from '@unboared/lib';

import { log } from '~/config/env';
import { useLinkTo } from '~/navigation/router';
import { gamesAPI } from '~/services/games_api';
import { GameGrid } from '~/screens/scenario/home/screen/components/collection/game_grid';
import { useGlobalPopup } from '~/components/screen/popup/global_popup';
import { useValidate } from '~/screens/scenario/home/screen/useValidate';
import { useAnalyticsAccessEvent } from '~/services/firebase';

const SearchContent = () => {
  const { normalize } = useNormalize();
  const [text, setText] = useState('');

  const [games, setGames] = useState([]);

  const linkTo = useLinkTo();
  const open = useGlobalPopup((state) => state.open);
  
  const actionOnPress = (game) => {
    linkTo(`/game/${game.id}`);
  };
  const { validate } = useValidate(undefined, actionOnPress);

  useListener('__updateSearch__', (data) => {
    setText(data.text);
  });

  useListener('__launchGame__', (data) => {
    gamesAPI
      .getGame(data.gameID)
      .then((game) => {
        validate(game);
      })
      .catch((error) => {
        console.error(error);
      });
  });

  useEffect(() => {
    gamesAPI
      .getGamesByName(text)
      .then((qgames: any) => {
        setGames(qgames);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [text]);

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'flex-start',
        padding: normalize(space.large),
      }}
    >
      <TextInput
        style={{ marginBottom: normalize(space.small) }}
        label="Search for a game"
        text={text}
        onChangeText={setText}
        placeholder="search..."
      />
      <GameGrid games={games} onPress={(game) => validate(game)} />
    </View>
  );
};
export const ScreenSearchPage = () => {
  // const [gameItems] = useGames()
  // const [selectedCategory, setSelectedCategory] = useState("all")
  
  log.debug('In Search');
  useAnalyticsAccessEvent("platform/search")

  const { loadScene } = useScene();
  useEffect(() => {
    loadScene('search');
  }, []);

  return <SearchContent />;
};
