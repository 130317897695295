import React from 'react';
import { StyleProp, TextStyle, ViewStyle, View } from 'react-native';
import { Heading, i18n, space, useNormalize, useTheme } from '@unboared/base-ui.all';
import { ShimmerPlaceholder } from '~/components/common/loading_placeholder';

export type SectionProps = {
  /**
   * Text which is looked up via i18n.
   */
  tx?: any;

  /**
   * Optional options to pass to i18n. Useful for interpolation
   * as well as explicitly setting locale or translation fallbacks.
   */
  txOptions?: i18n.TranslateOptions;

  /**
   * The text to display if not using `tx` or nested components.
   */
  text?: string;

  /**
   * Children components.
   */
  children?: React.ReactNode;

  /**
   * the size of the button
   */
  size?: number;

  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>;

  /**
   * An optional style override useful for the button text.
   */
  textStyle?: StyleProp<TextStyle>;

  /**
   * A boolean that indicates if the section is loading.
   */
  loading?: boolean;
};

export function Section({ size = 20, ...props }: SectionProps) {
  const {
    text,
    tx,
    txOptions,
    children,
    style: styleOverride,
    textStyle: textStyleOverride,
    loading,
  } = props;

  const theme = useTheme();
  const { normalize } = useNormalize();

  const viewStyles = [styleOverride];
  const textStyles = [
    { fontFamily: 'MontserratBold' },
    textStyleOverride,
  ];

  return (
    <View style={viewStyles}>
      <View style={{ marginVertical: 0.5 * normalize(space.tiny) }}>
        <ShimmerPlaceholder height={normalize(theme.sizeH2)} visible={!loading}>
          <Heading
            type="h2"
            tx={tx}
            text={text}
            txOptions={txOptions}
            style={textStyles}
          />
        </ShimmerPlaceholder>
      </View>
      {children}
    </View>
  );
}
