import React from "react";
import { usePlayers } from "@unboared/lib";
import { WaitingView } from "~/components/gamepad/view/waiting_view";
import { PauseMenuNavigation } from "./pause_menu_navigation";

export const PauseMenuGamepad = () => {
  const {isMaster} = usePlayers();
  if (isMaster()) {
    return <PauseMenuNavigation />
  }
  else {
    return <WaitingView />
  }
}
