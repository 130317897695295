import React from "react";
import { usePlayers } from "@unboared/lib";
import { WaitingView } from "~/components/gamepad/view/waiting_view";
import { GlobalPopupNavigation } from "./global_popup_navigation";

export const GlobalPopupGamepad = () => {
  const {isMaster} = usePlayers();
  if (isMaster()) {
    return <GlobalPopupNavigation />
  }
  else {
    return <WaitingView />
  }
}
