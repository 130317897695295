import { createGlobalStorage } from '@unboared/utils.storage';

export type LocationType = {
  pathname: string;
  search: string;
  hash: string;
  state: any;
  key: string;
};

export type LocationMemoState = {
  location?: LocationType;
};

export type ActionOnLocationMemo = {
  setLocation: (locationMemo: LocationType) => void;
  clearLocation: () => void;
};

export const useLocationMemo = createGlobalStorage<
  LocationMemoState & ActionOnLocationMemo
>((set: any) => ({
  location: undefined,
  setLocation: (locationMemo: LocationType) => {
    set({ location: locationMemo });
  },
  clearLocation: () => {
    set({ location: undefined });
  },
}));
