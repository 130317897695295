import React from 'react';
import styled from 'rn-css';
import { Platform, View } from 'react-native';

import {
  useCommunication,
  usePlayers,
  useCustomDeviceStateProperty,
} from '@unboared/lib';

import { useNormalize, space, Button, Theme } from '@unboared/base-ui.all';
import { FullscreenButton } from '~/components/common/system_actions/fullscreen';
import { ConditionalPauseMenu } from '~/components/gamepad/view/pause_menu';
import { ConditionalConnexionInfos } from '~/components/gamepad/view/connexion_infos';
import { ConditionalGlobalPopup } from '~/components/gamepad/view/global_popup';

export type InGameLayoutProps = {
  /**
   * the children nodes.
   */
  children: any;
};

export const InGameLayout = ({ children }: InGameLayoutProps) => {
  const { normalize } = useNormalize();
  const { emitAction } = useCommunication();
  const { isMaster, getHostID } = usePlayers();
  const { getCustomDeviceStateProperty } = useCustomDeviceStateProperty();
  const pauseValue = getCustomDeviceStateProperty(getHostID(), 'pauseMenu');

  return (
    <View style={{ flex: 1, height: '100%', width: '100%' }}>
      {isMaster() && (
        <TopRightView marginSize={normalize(space.smaller)}>
          <Button
            preset="secondary"
            icon={pauseValue ? 'io-play' : 'pause'}
            onPress={() => emitAction('pressPause')}
          />
        </TopRightView>
      )}
      {Platform.OS == 'web' && (
        <TopLeftView marginSize={normalize(space.smaller)}>
          <FullscreenButton size={20} />
        </TopLeftView>
      )}
      <ConditionalPauseMenu />
      <ConditionalGlobalPopup/>
      <ConditionalConnexionInfos />
      <View style={{ flex: 1 }}>{children}</View>
    </View>
  );
};

const TopLeftView = styled.View<{ marginSize: number }>`
  position: absolute;
  flex-direction: row;
  top: ${(props) => props.marginSize};
  left: ${(props) => props.marginSize};
  z-index: 99;
  elevation: 99;
`;

const TopRightView = styled.View<{ marginSize: number }>`
  position: absolute;
  top: ${(props) => props.marginSize};
  right: ${(props) => props.marginSize};
  z-index: 99;
`;
