import React from "react";

import { ScreenHomePage } from "~/screens/scenario/home/screen";
import { NavigationView } from "~/screens/scenario/home/gamepad";

import { ScreenSearchPage } from "~/screens/scenario/search/screen";
import { NavigationSearch } from "~/screens/scenario/search/gamepad";

import { ScreenSettingsPage } from "~/screens/scenario/settings/screen";
import { NavigationSettings } from "~/screens/scenario/settings/gamepad";

import { ScreenInGamePage } from "~/screens/scenario/in_game/screen";

import { Screen404Page } from "~/screens/scenario/404/screen";
import { Gamepad404Page } from "~/screens/scenario/404/gamepad";

export const PlatformScenario = {
  "home": {
    screen: <ScreenHomePage />,
    gamepad: <NavigationView activePage="games" />
  },
  "search": {
    screen: <ScreenSearchPage />,
    gamepad: <NavigationSearch activePage="search" />
  },
  "settings": {
    screen: <ScreenSettingsPage />,
    gamepad: <NavigationSettings activePage="settings" />
  },
  "in_game": {
    screen: <ScreenInGamePage />,
    gamepad: <NavigationView activePage="search" />
  },
  "404": {
    screen: <Screen404Page />,
    gamepad: <Gamepad404Page />
  },
}

