import React, { useEffect } from 'react';

import { BlurredOverlay } from '@unboared/base-ui.all';
import {
  useCustomDeviceStateProperty,
  useListener,
  usePlayers,
} from '@unboared/lib';

import { VISUAL_CONNEXION_INFOS_SCREEN } from '~/config/const';
import { useConnexionInfos } from '~/utils/connexion_infos';
import {
  ConnexionInfosPage,
  ConnexionInfosModal,
  ConnexionInfosWithBlocks,
} from '~/components/screen/view/connexion_infos';

export const ConnexionInfosPopup = () => {
  const { showConnexionInfos, toggleShowConnexionInfos } = useConnexionInfos();
  const { getHostID } = usePlayers();
  const { loadCustomDeviceStateProperty } = useCustomDeviceStateProperty();

  useEffect(() => {
    loadCustomDeviceStateProperty(
      getHostID(),
      'connexionInfos',
      showConnexionInfos,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConnexionInfos]);

  useListener('pressConnexionInfos', () => {
    toggleShowConnexionInfos();
  });

  let ConnexionInfosPageComponent;
  switch (VISUAL_CONNEXION_INFOS_SCREEN) {
    case 'Video':
      ConnexionInfosPageComponent = ConnexionInfosPage;
      break;
    case 'Blocks':
      ConnexionInfosPageComponent = ConnexionInfosWithBlocks;
      break;
    case 'Modal':
      ConnexionInfosPageComponent = ConnexionInfosModal;
      break;
    default:
      ConnexionInfosPageComponent = ConnexionInfosPage;
      break;
  }

  return (
    <BlurredOverlay active={showConnexionInfos}>
      <ConnexionInfosPageComponent onClose={toggleShowConnexionInfos} />
    </BlurredOverlay>
  );
};
