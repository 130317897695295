import React, { useEffect } from 'react';
import { useCustomDeviceStateProperty, usePlayers } from '@unboared/lib';
import { createGlobalStorage } from '@unboared/utils.storage';

interface PauseMenuState {
  showPauseMenu: boolean;
  toggleShowPauseMenu: () => void;
}

export const usePauseStore = createGlobalStorage<PauseMenuState>((set) => ({
  showPauseMenu: false,
  toggleShowPauseMenu: () =>
    set((state) => ({ showPauseMenu: !state.showPauseMenu })),
}));

export const usePauseMenu = () => {
  const { getDeviceID } = usePlayers();
  const { loadCustomDeviceStateProperty } = useCustomDeviceStateProperty();
  const { showPauseMenu, toggleShowPauseMenu } = usePauseStore();

  useEffect(() => {
    loadCustomDeviceStateProperty(getDeviceID(), 'pauseMenu', showPauseMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPauseMenu]);

  return { showPauseMenu, toggleShowPauseMenu };
};
