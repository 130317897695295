import React from 'react';
import styled from 'rn-css';
import { usePlayers, useCustomDeviceStateProperty } from '@unboared/lib';

import { Theme } from '@unboared/base-ui.all';
import { PauseMenuGamepad } from '~/components/gamepad/view/pause_menu';

export const ConditionalPauseMenu = () => {
  const { getHostID } = usePlayers();
  const { getCustomDeviceStateProperty } = useCustomDeviceStateProperty();

  const pauseValue = getCustomDeviceStateProperty(getHostID(), 'pauseMenu');

  return (
    <>
      {pauseValue && (
        <PauseOver>
          <PauseMenuGamepad />
        </PauseOver>
      )}
    </>
  );
};

const PauseOver = styled.View`
  position: absolute;
  background-color: ${({ theme }) => (theme as Theme).backgroundColor};
  top: 0;
  left: 0;
  z-index: 8;
  elevation: 8;
  width: 100%;
  height: 100%;
`;
