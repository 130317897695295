import React from "react"
import { View } from "react-native"
import { Pad } from "@unboared/design.all"
import { SystemActionsShortcuts } from "./sa_shortcuts"

export type NavigationPadProps = {
    pressOk: () => void,
    pressUp: () => void,
    pressDown: () => void,
    pressLeft: () => void,
    pressRight: () => void
}

export const NavigationPad = ({ pressOk, pressUp, pressDown, pressLeft, pressRight, style }: any) => {
    const customStyle = [{ alignItems: 'center' }, style]

    return (
        <View style={customStyle}>
            <View style={{ flex: 0.2 }}>
                <SystemActionsShortcuts />
            </View>
            <View style={{ flex: 1 }}>
                <Pad
                    pressOk={pressOk}
                    pressUp={pressUp}
                    pressDown={pressDown}
                    pressLeft={pressLeft}
                    pressRight={pressRight}
                    size={160}
                />
            </View>
        </View>
    )
}