import React, { useState } from 'react';

import { useCommunication } from '@unboared/lib';
import { PortraitMobileLayout, Button, Text } from '@unboared/base-ui.all';
import { useScreenInfos } from '@unboared/utils.orientation';


import { log } from '~/config/env';
import { GamepadPortraitLayout } from '~/config/layouts';

import { ChangeScreenOrientationPage } from '~/screens/common/change_screen_orientation';
import { NavGamepad } from './components/nav_gamepad';
import { NavigationPad } from '~/components/gamepad/view/navigation_pad';


export const NavigationView = ({ activePage }) => {
  log.debug('In Home');

  const { isPortrait } = useScreenInfos();
  const { emitAction } = useCommunication();

  if (!isPortrait) {
    return <ChangeScreenOrientationPage to="portrait" />;
  }

  return (
    <PortraitMobileLayout
      flex={GamepadPortraitLayout.ClassicLayoutWithNav}
      header={<></>}
      footer={<NavGamepad activePage={activePage} />}
    >
      <NavigationPad
        pressOk={() => emitAction('__ok__')}
        pressUp={() => emitAction('__up__')}
        pressDown={() => emitAction('__down__')}
        pressLeft={() => emitAction('__left__')}
        pressRight={() => emitAction('__right__')}
      />
    </PortraitMobileLayout>
  );
};
