import React, { useState } from 'react';
import { useMargin } from '~/screens/common/signin';
import { ExtraSignInButton } from '~/screens/common/signin2';
import { useLinkTo } from '~/navigation/router';

export const EmailPasswordButton = () => {
  const { smallMargin } = useMargin();

  const linkTo = useLinkTo();

  const onSubmit = () => {
    linkTo('/login/email');
  };

  return (
    <ExtraSignInButton
      style={smallMargin}
      tx="common.signin.email_password.submitButton"
      onPress={onSubmit}
    />
  );
};
