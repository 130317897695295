import React from 'react';
import { View, StyleSheet, Pressable, TouchableHighlight } from 'react-native';
import styled from 'rn-css';
import { useLinkTo } from '~/navigation/router';

import { usePlayers } from '@unboared/lib';
import { useTheme } from '@unboared/utils.theme';
import {
  useNormalize,
  Heading,
  useTranslate,
  space,
  Text,
  Icon,
  Button,
} from '@unboared/base-ui.all';
import {
  Logo,
  Player,
  ConnectedPlayers,
  ConnexionButton,
} from '@unboared/design.all';

import { SystemActionsWidget } from '~/components/screen/system_actions';
import { useConnexionInfos } from '~/utils/connexion_infos';

export const InPlatformLayout = ({ children }: any) => {
  const linkTo = useLinkTo();

  const goToHome = () => {
    linkTo('/');
  };

  const { normalize } = useNormalize();
  const PADDING = normalize(space.medium);
  const { translate } = useTranslate();

  const {
    getMasterID,
    getUsername,
    getAvatar,
    getColor,
    getPlayers,
    getUID,
  } = usePlayers();
  const masterID = getMasterID();

  const theme = useTheme((state) => state.theme);
  const { toggleShowConnexionInfos } = useConnexionInfos();

  // const onPlay = useSoundManager((state) => state.onPlay);

  // useEffect(() => {
  //     onPlay();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  let masterPlayerComponent;
  if (masterID) {
    masterPlayerComponent = (
      <View
        style={{
          position: 'absolute',
          margin: PADDING,
          top: 0,
          right: 0,
          zIndex: 99,
        }}
      >
        <Player
          username={getUsername(masterID)}
          avatar={getAvatar(masterID)}
          color={getColor(masterID)}
          size={30}
          usernameStyle={{ fontWeight: 'bold' }}
          master
        />
      </View>
    );
  }

  return (
    <StyledUnboaredUI>
      {masterPlayerComponent || null}
      <View
        style={[
          styles.infos,
          { padding: PADDING /* , backgroundColor: 'red' */ },
        ]}
      >
        <Pressable onPress={goToHome}>
          <Logo preset="beta" size={24} />
        </Pressable>
        <View
          style={{
            flex: 1,
            marginVertical: normalize(space.small),
            alignItems: 'flex-start',
          }}
        >
          <ConnectedPlayers
            players={getPlayers()}
            masterID={getUID(masterID)}
            maxDisplay={4}
            size={30}
            title={translate('screen.gamesPage.connectedPlayers')}
          />
          <Button
            style={{ marginTop: normalize(space.small) }}
            preset="secondary"
            textPreset='small'
            icon="addPlayer"
            tx="screen.gamesPage.connexionHelp2"
            onPress={toggleShowConnexionInfos}
          />
        </View>
        <View style={{ alignItems: 'flex-start' }}>
          <SystemActionsWidget size={24} />
        </View>
      </View>

      <View style={styles.content}>{children}</View>
    </StyledUnboaredUI>
  );
};

const StyledUnboaredUI = styled.View`
  flex: 1;
  flex-direction: row;
`;

const styles = StyleSheet.create({
  infos: {
    flex: 15,
  },
  content: {
    flex: 85,
  },
});
