import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import styled from 'rn-css';

import { useNormalize } from '@unboared/base-ui.responsive.sizes';
import { useScreenInfos } from '@unboared/utils.orientation';

import {
  useCustomDeviceStateProperty,
  useCommunication,
  usePlayers,
} from '@unboared/lib';

import {
  ConnexionButton as ConnexionButtonComponent,
  FavoriteButton,
  MuteButton,
  SystemActionButton,
} from '@unboared/design.all';

const SystemActionPosition = styled.View<{
  top?: number;
  left?: number;
  right?: number;
}>`
  flex: 1;
  top: ${(props) => props.top};
  align-items: center;
  justify-content: center;
`;

/**
 * The component used to display system actions over the gamepad.
 * This component will be displayed with position based on a circular component
 * displayed below it.
 */
export const SystemActionsShortcuts = ({ size = 20 }: { size?: number }) => {
  // Get UI informations
  const { normalize } = useNormalize();
  const screen = useScreenInfos();

  // Setup UI variables
  const positions = {
    sound: { top: normalize(size / 2) },
    love: { top: normalize(-size / 2) },
    connexion: { top: normalize(size / 2) },
  };

  // Get unboared informations
  const { getCustomDeviceStateProperty } = useCustomDeviceStateProperty();
  const { emitAction } = useCommunication();
  const { getHostID } = usePlayers();

  // Setup UI variables
  const volumeValue = getCustomDeviceStateProperty(getHostID(), 'volume');
  const coValue = getCustomDeviceStateProperty(getHostID(), 'connexionInfos');

  const activeVolume = volumeValue !== undefined ? volumeValue == 0 : true;
  const activeConnect = coValue !== undefined ? coValue : true;

  return (
    <View style={[styles.container, { width: screen.width }]}>
      <SystemActionPosition {...positions.sound}>
        <ConnexionButtonComponent
          size={size}
          onPress={() => emitAction('pressConnexionInfos')}
          active={activeConnect}
        />
      </SystemActionPosition>
      <SystemActionPosition {...positions.love}>
        <SystemActionButton
          onIcon="search"
          offIcon="search"
          active={false}
          size={size}
          onPress={() => emitAction('pressUnboaredSearch')}
        />
      </SystemActionPosition>
      <SystemActionPosition {...positions.connexion}>
        <MuteButton
          size={size}
          onPress={() => emitAction('pressMuteButton')}
          active={activeVolume}
        />
      </SystemActionPosition>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
});
