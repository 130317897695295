import React from 'react'
import { Text, Alert, accent_color, useTranslate, Icon } from '@unboared/base-ui.all';

export type NotEnoughPlayersProps = {
  /**
   * the unavailable game
   */
  game: string,

  /**
   * the minimal number of players required
   */
  minPlayers: number
}

export const NotEnoughPlayers = ({ game, minPlayers }) => {
  const { translate } = useTranslate()
  const headerMessage = `✋ ${translate("screen.alerts.not_enough_players.title")}`;

  return (
    <Alert title={headerMessage}>
      <Text preset='big'>
        {translate("screen.alerts.not_enough_players.message1_1")}
        <Text preset='big' style={{ fontWeight: '900' }} text={` ${game} `} />
        {translate("screen.alerts.not_enough_players.message1_2")}
        <Text preset='big' style={{ color: accent_color.important, fontWeight: '900' }} text={` ${minPlayers} ${translate("screen.alerts.not_enough_players.message1_3")}`} />
      </Text>

      <Text preset='big'>
        {`${translate("screen.alerts.not_enough_players.message2_1")} `}
        <Icon name="addPlayer" size={15} color={accent_color.important} />
        {` ${translate("screen.alerts.not_enough_players.message2_2")}`}
      </Text>
    </Alert>
  )
}
