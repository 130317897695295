import React, { useEffect, useCallback, useState } from 'react';
import { api, useListener, usePlayers } from '@unboared/lib';
import { useLanguage } from '@unboared/utils.language';

const TRANSMIT_CODE = 'unboared::webview::transmit';

/**
 * Bridge between the platform and the WebView game.
 * @param gameName le nom du jeu sous jacent
 * @param src l'url vers le jeu
 * @returns la référence vers le webview, s'il le webview (donc le jeu) est chargé
 */
export const useWebViewPlugin = (webviewRef: any, src: string) => {
  // Instanciate a reference to the webview
  const [webviewLoaded, setWebviewLoaded] = useState(false);
  const language = useLanguage((state) => state.language);

  const { getPlayer } = usePlayers();

  // Envoie un message au Webview sous-jacent
  // -- ex : { }
  const postToWebView = useCallback(
    (message: any) => {
      if (!webviewRef.current) return;
      webviewRef.current.contentWindow.postMessage(message, src);
    },
    [webviewRef]
  );

  /* Listen from onConnect method from parent and delegates to the child */
  useEffect(() => {
    const unsubscribe = api().onConnect((deviceID: string) => {
      postToWebView({
        action: 'connect',
        data: {
          deviceID,
          data: {
            state: api().getDeviceState(deviceID),
            player: getPlayer(deviceID),
          },
        },
      });
    });
    return unsubscribe;
  }, []);

  /* Listen from onDisonnect method from parent and delegates to the child */
  useEffect(() => {
    const unsubscribe = api().onDisconnect((deviceID: string) => {
      postToWebView({
        action: 'disconnect',
        data: { deviceID },
      });
    });
    return unsubscribe;
  }, []);

  /* Listen from on method from parent and delegates to the child */
  useEffect(() => {
    const unsubscribe = api().onPlayerChange((deviceID: string) => {
      postToWebView({
        action: 'setPlayer',
        data: {
          deviceID,
          player: getPlayer(deviceID),
        },
      });
    });
    return unsubscribe;
  }, []);

  /* Listen from on method from parent and delegates to the child */
  useEffect(() => {
    const unsubscribe = api().onDeviceStateChange((deviceID: string) => {
      postToWebView({
        action: 'setDeviceState',
        data: {
          deviceID,
          state: api().getDeviceState(deviceID),
        },
      });
    });
    return unsubscribe;
  }, []);

  /* Listen from on method from parent and delegates to the child */
  useEffect(() => {
    const unsubscribe = api().onDeviceStatePropertyChange(
      (deviceID: string, key: string) => {
        postToWebView({
          action: 'setDeviceStatePropertyChange',
          data: {
            deviceID,
            key,
            value: api().getDeviceStateProperty(deviceID, key),
          },
        });
      }
    );
    return unsubscribe;
  }, []);

  /* Listen from onMute method from parent and delegates to the child */
  useEffect(() => {
    const unsubscribe = api().onMute((value: boolean) => {
      postToWebView({
        action: 'mute',
        data: { value },
      });
    });
    return unsubscribe;
  }, []);

  /* Listen from onResume method from parent and delegates to the child */
  useEffect(() => {
    const unsubscribe = api().onResume(() => {
      postToWebView({ action: 'resume' });
    });
    return unsubscribe;
  }, []);

  /* Listen from onPause method from parent and delegates to the child */
  useEffect(() => {
    const unsubscribe = api().onPause(() => {
      postToWebView({ action: 'pause' });
    });
    return unsubscribe;
  }, []);

  // Transmit child level messages without interfering
  // with the parent application
  useListener(TRANSMIT_CODE, postToWebView, []);

  // Receive messages from the underlying WebView (the child app)
  // and process it
  // -- ex : { }
  useEffect(() => {
    const webviewMessageHandler = (event: any) => {
      let data = event.data;
      if (data.action === 'onReady') {
        setWebviewLoaded(true);
        //
        let devices: Array<any> = [];
        for (let screenID of api().getScreenIDs()) {
          devices.push({
            deviceID: screenID,
            data: {
              player: getPlayer(screenID),
              state: api().getDeviceState(screenID),
            },
          });
        }

        for (let gamepadID of api().getGamepadIDs()) {
          devices.push({
            deviceID: gamepadID,
            data: {
              player: getPlayer(gamepadID),
              state: api().getDeviceState(gamepadID),
            },
          });
        }

        postToWebView({
          action: 'ready',
          data: {
            hostID: api().getHostID(),
            myDeviceID: api().getDeviceID(),
            devices: devices,
            session: {
              id: api().getSessionID(),
              url: api().getSessionLink(),
            },
            mute: api().isMute(),
            language: language,
          },
        });
      } else if (data.action == 'send') {
        api().send(data.to, TRANSMIT_CODE, data.data);
      } else if (data.action == 'broadcast') {
        api().broadcast(TRANSMIT_CODE, data.data);
      } else if (data.action == 'navigateToHome') {
        api().navigateToHome();
      } else if (data.action == 'navigateTo') {
        api().navigateTo(data.data.url);
        //   } else if (data.action == "showAd") {
        //     api().showAd();
        //   } else if (data.action == "requestHighScores") {
        //     api().requestHighScores(data.level_name, data.level_version, data.uids, data.ranks, data.total, data.top);
        //   } else if (data.action == "storeHighScore") {
        //     api().storeHighScore(data.level_name, data.level_version, data.score, data.uid, data.data, data.score_string);
        //   } else if (data.action == "requestPersistentData") {
        //     api().requestPersistentData(data.uids);
        //   } else if (data.action == "storePersistentData") {
        //     api().storePersistentData(data.key, data.value, data.uid);
        //   } else if (data.action == "debug") {
        //     console.log("debug message:", data.data);
        //   } else if (
        //     data.action === 'requestHighScores'
        //     ) {
        //       // Call async function to get highscores
        //       const requestHighscpores = async () => {
        //     return {};
        //   };
        //   requestHighscores().then((highscores) => {
        //     postToWebView({
        //       action: 'onHighScores',
        //       data: highscores,
        //     });
        //   });
        // }
      } else if (data.action == 'debug') {
        console.log('[Debug] Debug message:', data.data);
      }
    };

    //
    window.addEventListener('message', webviewMessageHandler);

    return () => window.removeEventListener('message', webviewMessageHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isReady: webviewLoaded };
};
