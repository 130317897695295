import React from 'react';
import { View, Platform } from 'react-native';

import { useScreenInfos } from '@unboared/utils.orientation';
import {
  ConnexionInstructionsWithBlocks,
  getRoomURL,
} from '@unboared/design.all';

import { useSession, usePlayers } from '@unboared/lib';
import { ConnexionInfosProps } from './connexion_infos';

var host =
  Platform.OS === 'web'
    ? window.location.protocol + '//' + window.location.host
    : 'https://play.unboared.com/';

export const ConnexionInfosWithBlocks = ({ onClose }: ConnexionInfosProps) => {
  // Get utils for responsiveness
  const { height, width, isPortrait } = useScreenInfos();

  // Get informations about the room (or session)
  const { getSessionID, getSessionLink } = useSession();
  const { getPlayers, getMasterID } = usePlayers();

  return (
    <View
      style={{
        width: width,
        height: height,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <View style={{ width: '70%', height: '85%' }}>
        <ConnexionInstructionsWithBlocks
          roomID={getSessionID()}
          url={getSessionLink()}
          connectedPlayers={getPlayers()}
          masterID={getMasterID()}
        />
      </View>
    </View>
  );
};
