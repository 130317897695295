import React from "react";

import { Heading, PortraitMobileLayout, Text, useNormalize } from "@unboared/base-ui.all";
import { useScreenInfos } from "@unboared/utils.orientation";

import { log } from "~/config/env";
import { ChangeScreenOrientationPage } from "~/screens/common/change_screen_orientation";
import { NavGamepad } from "~/screens/scenario/home/gamepad/components/nav_gamepad";

export const Gamepad404Page = () => {
  log.debug("In Settings")
  const { normalize } = useNormalize()

  const { isPortrait } = useScreenInfos();
  if (!isPortrait) {
    return <ChangeScreenOrientationPage to='portrait' />
  }

  return (
    <PortraitMobileLayout
      flex={{ header: 0., content: 0.9, footer: 0.1 }}
      // header={}
      footer={<NavGamepad />}
    >
      <>
        <Heading text="Error 404" />
        <Text style={{ fontSize: normalize(22) }} text="Page not found" />
      </>
    </PortraitMobileLayout >
  )
}
