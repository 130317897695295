import React from 'react';

import { usePlayers, useSession } from '@unboared/lib';
import { Modal } from '@unboared/base-ui.modals.modal';
import { ConnexionInstructions, getRoomURL } from '@unboared/design.all';
import { ConnexionInfosProps } from './connexion_infos';

export const ConnexionInfosModal = ({ onClose }: ConnexionInfosProps) => {
  const { getSessionID, getSessionLink } = useSession();
  const { getPlayers, getMasterID } = usePlayers();

  return (
    <Modal>
      <ConnexionInstructions
        roomID={getSessionID()}
        url={getSessionLink()}
        connectedPlayers={getPlayers()}
        masterID={getMasterID()}
      />
    </Modal>
  );
};
