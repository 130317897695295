import React, { useEffect, useRef } from 'react';
import {
  StyleProp,
  ViewStyle,
  FlatList,
  Button,
  Text,
  View,
  Image,
} from 'react-native';

import { Heading, space, useNormalize, useTheme } from '@unboared/base-ui.all';

import { GameCollectionProps } from '../game_collection';
import { useAuth } from '~/services/auth';
import { GameCard } from '~/components/common/card/game_card';
import { ShimmerPlaceholder } from '~/components/common/loading_placeholder';

export const GameList = ({
  games,
  activeGame,
  size = 100,
  style,
  loading,
  onPress = (game: any) => console.log(game),
}: GameCollectionProps) => {
  const { auth } = useAuth();

  const flatListRef = useRef<any>();
  const { normalize } = useNormalize();
  const normalizedSize = normalize(size);

  const itemStyles = {
    padding: 0.05 * normalizedSize,
    marginRight: 0.1 * normalizedSize,
  };

  useEffect(() => {
    if (activeGame && !loading) {
      let index = games.findIndex((game) => game.id === activeGame);
      flatListRef.current.scrollToIndex({ animated: true, index });
    }
  }, [activeGame]);

  if (loading) {
    return (
      <View style={{ flexDirection: 'row' }}>
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
        <CardGameSkeleton size={size} />
      </View>
    );
  }

  return (
    <FlatList
      ref={flatListRef}
      style={style}
      data={games}
      renderItem={({ item }) => (
        <GameCard
          {...item}
          active={item.id == activeGame}
          size={size}
          style={itemStyles}
          disable={!auth.isLogin && item.accessibility !== 'free'}
          onPress={() => onPress(item)}
        />
      )}
      keyExtractor={(item) => item.title}
      horizontal
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    />
  );
};

export const CardGameSkeleton = ({ size }) => {
  const { normalize } = useNormalize();
  const normalizedSize = normalize(size);
  const theme = useTheme();

  return (
    <View
      style={{
        padding: 0.1 * normalizedSize,
        marginRight: 0.05 * normalizedSize,
      }}
    >
      <ShimmerPlaceholder
        height={normalize(0.8 * size)}
        width={normalize(0.8 * size)}
        visible={false}
      >
        <Image
          style={{
            width: normalize(size),
            height: normalize(size),
            alignSelf: 'center',
          }}
          resizeMode="contain"
        />
      </ShimmerPlaceholder>
      <View style={{ marginTop: normalize(space.tiny) }}>
        <ShimmerPlaceholder height={normalize(theme.sizeH4)} width={normalize(0.5 * size)} visible={false}>
          <Heading type="h4" text="tmp" />
        </ShimmerPlaceholder>
      </View>
    </View>
  );
};
