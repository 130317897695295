import React from 'react';
import {
  usePlayers,
  useCommunication,
  useCustomDeviceStateProperty,
} from '@unboared/lib';
import { View } from 'react-native';

import {
  PortraitMobileLayout,
  size,
  space,
  Text,
  useTheme,
} from '@unboared/base-ui.all';
import { useScreenInfos } from '@unboared/utils.orientation';

import { ChangeScreenOrientationPage } from '~/screens/common/change_screen_orientation';
import { SystemActionsShortcuts } from '../navigation_pad/sa_shortcuts';
import { WaitingView } from '~/components/gamepad/view/waiting_view';
import { GamepadPortraitLayout } from '~/config/layouts';

import { HideMessage } from './hide_message';

export const ConnexionInfosGamepad2 = () => {
  const { isMaster } = usePlayers();
  if (isMaster()) {
    return <ConnexionInfosHideShow />;
  } else {
    return <WaitingView />;
  }
};

const ConnexionInfosHideShow = () => {
  const theme = useTheme();

  const { isPortrait } = useScreenInfos();
  if (!isPortrait) {
    return <ChangeScreenOrientationPage to="portrait" />;
  }

  return (
    <PortraitMobileLayout
      flex={GamepadPortraitLayout.ClassicLayoutWithNav}
      header={<HideMessage />}
    >
      <View
        style={{
          flex: 1,
          alignSelf: 'stretch',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <View style={{ flex: 0.2 }}>
          <SystemActionsShortcuts />
        </View>
        <View style={{ flex: 0.8 }}></View>
      </View>
    </PortraitMobileLayout>
  );
};
