import { View } from 'react-native';
import React, { useState } from 'react';
import { log } from '~/config/env';

import { useCommunication } from '@unboared/lib';
import { useScreenInfos } from '@unboared/utils.orientation';
import { PortraitMobileLayout, Text } from '@unboared/base-ui.all';

import { NavigationPad } from '~/components/gamepad/view/navigation_pad';
import { ChangeScreenOrientationPage } from '~/screens/common/change_screen_orientation';
import { NavGamepad } from '~/screens/scenario/home/gamepad/components/nav_gamepad';
import { GamepadPortraitLayout } from '~/config/layouts';

export const NavigationSettings = ({ activePage }) => {
  log.debug('In Settings');
  const { isPortrait } = useScreenInfos();
  const { emitAction } = useCommunication();

  if (!isPortrait) {
    return <ChangeScreenOrientationPage to="portrait" />;
  }

  return (
    <PortraitMobileLayout
      flex={GamepadPortraitLayout.ClassicLayoutWithNav}
      header={<Text tx="gamepad.settingPage.help" />}
      footer={<NavGamepad activePage={activePage} />}
    >
      <NavigationPad
        pressOk={() => emitAction('__ok__')}
        pressUp={() => emitAction('__up__')}
        pressDown={() => emitAction('__down__')}
        pressLeft={() => emitAction('__left__')}
        pressRight={() => emitAction('__right__')}
      />
    </PortraitMobileLayout>
  );
};
