import { View } from 'react-native';
import React, { useEffect, useState } from 'react';

import {
  PortraitMobileLayout,
  space,
  Text,
  TextInput,
  useNormalize,
} from '@unboared/base-ui.all';
import { useScreenInfos } from '@unboared/utils.orientation';
import { useCommunication } from '@unboared/lib';

import { ChangeScreenOrientationPage } from '~/screens/common/change_screen_orientation';
import { NavGamepad } from '~/screens/scenario/home/gamepad/components/nav_gamepad';
import { gamesAPI } from '~/services/games_api';
import { GameGrid } from '~/screens/scenario/home/screen/components/collection/game_grid';
import { log } from '~/config/env';

export const NavigationSearch = ({ activePage }) => {
  log.debug('In Search');

  const { emitAction } = useCommunication();
  const { normalize } = useNormalize();

  const [text, setText] = useState('');
  const [games, setGames] = useState([]);

  useEffect(() => {
    log.debug('In Search : Text Change');
    gamesAPI
      .getGamesByName(text)
      .then((qgames: any) => {
        setGames(qgames);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [text]);

  const { isPortrait } = useScreenInfos();
  if (!isPortrait) {
    return <ChangeScreenOrientationPage to="portrait" />;
  }

  const updateText = (text: string) => {
    setText(text);
    emitAction('__updateSearch__', { text: text });
  };

  const launchGame = (gameID: string) => {
    emitAction('__launchGame__', { gameID: gameID });
  };

  return (
    <PortraitMobileLayout
      flex={{ header: 0.6, content: 0.3, footer: 0.1 }}
      header={
        <GameGrid
          style={{ justifyContent: 'space-evenly' }}
          games={games}
          size={55}
          onPress={(game) => launchGame(game.id)}
        />
      }
      footer={<NavGamepad activePage={activePage} />}
      headerStyle={{ paddingTop: normalize(space.huge) }}
    >
      <TextInput
        label="Search for a game"
        text={text}
        onChangeText={updateText}
        placeholder="search..."
      />
    </PortraitMobileLayout>
  );
};
