import React, { useState } from 'react';
import { View } from 'react-native';
import { useLinkTo } from '~/navigation/router';

import {
  unboaredTheme,
  useTranslate,
  useNormalize,
  Modal,
  space,
  Button,
} from '@unboared/base-ui.all';
import { api, useListener } from '@unboared/lib';
import { useTheme } from '@unboared/utils.theme';
import { usePauseMenu } from '~/utils/pause_menu';

export type PauseMenuProps = {
  /**
   * the name of the current game
   */
  game: string;

  /**
   * what to do when closing is called.
   */
  onClose: () => void;
};

export function PauseMenu({ game, onClose }: PauseMenuProps) {
  const { theme } = useTheme();
  const currentTheme = theme || unboaredTheme.default;

  const { normalize } = useNormalize();
  const { translate } = useTranslate();

  const buttons = useButtons(game, onClose);
  const [selected, setSelected] = useState(0);

  useListener(
    '__up__',
    () => {
      setSelected((prev) => {
        // playMoveSound();
        if (prev > 0) {
          return prev - 1;
        }
        return buttons.length - 1;
      });
    },
    [buttons /* playMoveSound */],
  );

  useListener(
    '__down__',
    () => {
      setSelected((prev) => {
        // playMoveSound();
        if (prev + 1 < buttons.length) {
          return prev + 1;
        }
        return 0;
      });
    },
    [buttons],
  );

  useListener(
    '__ok__',
    () => {
      buttons[selected].callback();
    },
    [selected],
  );

  return (
    <Modal
      width={normalize(500)}
      title={translate('screen.pausePage.title')}
      color={currentTheme.backgroundColor}
      centered
    >
      <View style={{ flex: 1, paddingHorizontal: normalize(space.medium) }}>
        <View style={{ marginBottom: normalize(space.medium) }}>
          {buttons.map((button) => (
            <Button
              key={button.name}
              preset="secondary"
              tx={button.title}
              onPress={button.callback}
              size={15}
              style={{ marginBottom: normalize(space.small) }}
              active={button.name === buttons[selected].name}
            />
          ))}
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {system_actions.map((sa) => (
            <Button
              icon={sa.icon}
              style={{ backgroundColor: 'rgba(0,0,0,0)' }}
              key={sa.name}
              preset="secondary"
              tx={sa.title}
              onPress={() => buttons[selected].callback()}
            />
          ))}
        </View>
      </View>
    </Modal>
  );
}

const system_actions = [
  {
    name: 'addPlayer',
    icon: 'addPlayer',
    title: 'screen.pausePage.systemActions.connexionInfos',
  },
  {
    name: 'volume',
    icon: 'volumeOn',
    title: 'screen.pausePage.systemActions.volume',
  },
  {
    name: 'fullscreen',
    icon: 'fullscreenOff',
    title: 'screen.pausePage.systemActions.fullscreen',
  },
];

const useButtons = (game: string, onClose: () => void) => {
  const linkTo = useLinkTo();

  const callbackResume = () => {
    api().resume();
    onClose();
  };

  // const callbackRestart = () => {
  //   linkTo(`/game/${game.id}`);
  //   onClose();
  // };

  const callbackMainMenu = () => {
    linkTo('/');
    onClose();
  };

  return [
    {
      name: 'resume',
      title: 'screen.pausePage.buttons.resume.title',
      callback: callbackResume,
    },
    // {
    //   name: 'restart',
    //   title: 'screen.pausePage.buttons.restart.title',
    //   callback: callbackRestart,
    // },
    {
      name: 'home',
      title: 'screen.pausePage.buttons.home.title',
      callback: callbackMainMenu,
    },
  ];
};
