import styled from 'rn-css';
import { Heading, Text, Theme, color } from '@unboared/base-ui.all';

export const StyledGameCard = styled.TouchableOpacity<{ active?: boolean }>`
  align-items: center;
  justify-content: center;
`;

export const StyledGameCardImage = styled.Image<{
  size: number;
  disable?: boolean;
  active?: boolean;
}>`
  width: 100%;
  height: 80%;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disable ? 0.2 : 1.0)};
  border-radius: ${(props) => 0.15 * props.size};
  border: ${(props) => 0.04 * props.size} solid
    ${({ disable, active, theme }) =>
      active
        ? disable
          ? (theme as Theme).currentPalette.c100
          : color.primary
        : 'rgba(0,0,0,0)'};
`;

export const StyledGameCardTitle = styled(Text)<{
  disable?: boolean;
  active?: boolean;
}>`
  align-self: flex-start;
  opacity: ${(props) => (props.disable ? 0.2 : 1.0)};
  ${(props) => props.active && 'font-weight: bold;'}
  ${(props) =>
    props.active &&
    `color: ${
      props.disable ? (props.theme as Theme).currentPalette.c100 : color.primary
    };`}
`;
