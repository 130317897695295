import React from 'react';
import { Platform, Pressable, View, ViewStyle } from 'react-native';
import {
  Heading,
  Icon,
  Text,
  CheckBox,
  useNormalize,
  space,
  useTranslate,
  CheckBox2,
} from '@unboared/base-ui.all';
import { useMargin } from '~/screens/common/signin';
import { CGU } from '~/config/const';

export type TermsConditionsProps = {
  icon: string;
  txTitle: string;
  txDescription: string;
  txExtra?: string;
  checked?: boolean;
  onValueChange?: any;
  disabled?: boolean;
};

export const TermsConditions = ({
  icon,
  txTitle,
  txDescription,
  txExtra,
  checked,
  onValueChange,
  disabled,
}: TermsConditionsProps) => {
  const { normalize } = useNormalize();
  const { smallMargin } = useMargin();
  const { translate } = useTranslate();

  const styles = {
    container: {} as ViewStyle,
    subContainer: {
      flex: 1,
      flexDirection: 'row',
      ...smallMargin,
    } as ViewStyle,
  };

  const openCGU = () => {
    if (Platform.OS == 'web') {
      window.open(CGU, '_blank');
    } else {
      // Linking... // normal Linking react-native
      console.error('Cannot open CGU from native app');
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Icon name={icon} size={12} />
        <View
          style={{
            flex: 1,
            alignItems: 'flex-start',
            marginHorizontal: normalize(space.medium),
          }}
        >
          <Text style={{ fontFamily:'Inter', fontWeight:'bold' }} tx={txTitle} />
          <Text preset="small">
            {translate(txDescription)}{' '}
            <Pressable onPress={openCGU}>
              <Text style={{ textDecorationLine: 'underline' }} preset="caption">
                {translate(txExtra)}
              </Text>
            </Pressable>
          </Text>
        </View>
        <CheckBox2
          value={checked}
          onValueChange={onValueChange}
          disabled={disabled}
          size={16}
        />
      </View>
    </View>
  );
};
