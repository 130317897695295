import { View } from 'react-native';
import { Button, Text, useNormalize } from '@unboared/base-ui.all';

type NavGamepadButtonProps = {
  id?: string;
  title?: string;
  txTitle?: string;
  icon?: string;
  active?: boolean;
  onPress?: () => void;
};

export const NavGamepadButton = ({
  id,
  title,
  txTitle,
  icon,
  active,
  onPress,
}: NavGamepadButtonProps) => {
  const { normalize } = useNormalize();

  return (
    <View key={id}>
      <Text text={title} tx={txTitle} />
      <Button
        style={{ alignSelf:'center', maxWidth: normalize(40) }}
        preset="secondary"
        active={active}
        icon={icon}
        onPress={onPress}
      />
    </View>
  );
};
