import React, { useState } from 'react';
import { Image, View } from 'react-native';

import {
  Button,
  Heading,
  space,
  Text,
  useNormalize,
  useTranslate,
} from '@unboared/base-ui.all';
import { useGlobalPopup } from '~/components/screen/popup/global_popup';
import LockImg from '~/assets/img/Lock.png';

import { useListener } from '@unboared/lib';

type AccountLockAlertProps = {
  size: number;
  activeButton: number;
  onMoreGames: () => void;
  onCancel: () => void;
};

export const AccountLockAlertDevice = (props: AccountLockAlertProps) => {
  const { normalize } = useNormalize();
  const { size, onMoreGames, onCancel, activeButton } = props;

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <Image
        source={LockImg}
        style={{ width: normalize(size), height: normalize(size) }}
      />
      <Heading
        style={{ textAlign: 'center' }}
        tx="common.popupCreateAccount.titleMoreGames"
      />
      <View
        style={{
          marginVertical: normalize(space.small),
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text
          style={{ textAlign: 'center' }}
          tx="common.popupCreateAccount.description"
        />
        <Text
          style={{ textAlign: 'center' }}
          tx="common.popupCreateAccount.feature1"
        />
        <Text
          style={{ textAlign: 'center' }}
          tx="common.popupCreateAccount.feature2"
        />
        <Text
          style={{ textAlign: 'center' }}
          tx="common.popupCreateAccount.feature3"
        />
      </View>
      <View
        style={{
          alignSelf: 'stretch',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Button
          preset={activeButton ? 'secondary' : 'primary'}
          tx="common.popupCreateAccount.btn1"
          onPress={onMoreGames}
        />
        <Button
          preset={activeButton ? 'primary' : 'secondary'}
          tx="common.popupCreateAccount.btn2"
          onPress={onCancel}
        />
      </View>
    </View>
  );
};

export const AccountLockAlert = ({ size }: { size?: number }) => {
  const [currentButton, setCurrentButton] = useState<number>(0);

  const close = useGlobalPopup((state) => state.close);
  const getMoreGames = () => {
    window.location.reload();
    // linkTo('/signup');
  };

  useListener('global_popup_ok', () => {
    setCurrentButton((state) => {
      if (state) {
        close();
      } else {
        getMoreGames();
      }
      return state;
    });
  });

  useListener('global_popup_right', () => {
    setCurrentButton(1);
  });

  useListener('global_popup_left', () => {
    setCurrentButton(0);
  });

  useListener('pressMoreGames', getMoreGames);

  return (
    <AccountLockAlertDevice
      size={size || 60}
      onCancel={close}
      onMoreGames={getMoreGames}
      activeButton={currentButton}
    />
  );
};

// export const AccountLockAlertGamepad = ({ size }: { size?: number }) => {
//   const { emitAction } = useCommunication();

//   const cancel = () => emitAction('pressCancel');
//   const getMoreGames = () => emitAction('pressMoreGames');

//   return (
//     <AccountLockAlertDevice
//       size={size || 60}
//       onCancel={cancel}
//       onMoreGames={getMoreGames}
//     />
//   );
// };
