import React, { useEffect } from 'react';
import { View } from 'react-native';

import { useScene } from '@unboared/lib';
import { Heading, Text, useNormalize } from '@unboared/base-ui.all';

import { log } from '~/config/env';

const Error404Content = () => {
  const { normalize } = useNormalize()

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Heading text="Error 404" />
      <Text style={{ fontSize: normalize(22) }} text="Page not found" />
    </View>
  );

}
export const Screen404Page = () => {
  log.debug("In 404")

  const { loadScene } = useScene();
  useEffect(() => {
    loadScene('404');
  }, []);

  return <Error404Content />
}


