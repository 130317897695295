import React from 'react';
import { usePlayers, useScene } from '@unboared/lib';
import { PlatformScenario } from '~/config/scenario';
import { WaitingView } from '~/components/gamepad/view/waiting_view';

export const InPlatformGamepad = () => {
  const { isMaster } = usePlayers();
  const { getScene } = useScene();

  if (isMaster()) {
    return PlatformScenario[getScene()].gamepad;
  } else {
    return <WaitingView />;
  }
};
