import React from 'react';
import { View } from 'react-native';

import { useCommunication } from '@unboared/lib';
import { PortraitMobileLayout } from '@unboared/base-ui.all';
import { useScreenInfos } from '@unboared/utils.orientation';

import { GamepadPortraitLayout } from '~/config/layouts';
import { ChangeScreenOrientationPage } from '~/screens/common/change_screen_orientation';
import { NavigationPad } from '~/components/gamepad/view/navigation_pad';

export const PauseMenuNavigation = () => {
  const { emitAction } = useCommunication();

  const { isPortrait } = useScreenInfos();
  if (!isPortrait) {
    return <ChangeScreenOrientationPage to="portrait" />;
  }

  return (
    <PortraitMobileLayout
      flex={GamepadPortraitLayout.ClassicLayoutWithNav}
      header={<></>}
    >
      <View style={{ flex: 1, justifyContent: 'flex-end' }}>
        <NavigationPad
          pressOk={() => emitAction('__ok__')}
          pressUp={() => emitAction('__up__')}
          pressDown={() => emitAction('__down__')}
          pressLeft={() => emitAction('__left__')}
          pressRight={() => emitAction('__right__')}
        />
      </View>
    </PortraitMobileLayout>
  );
};
