import React from 'react';
import { ViewStyle, StyleProp, StyleSheet } from 'react-native';

import { Widget } from '@unboared/design.all';
import { useNormalize } from '@unboared/base-ui.responsive.sizes';
import { space } from '@unboared/base-ui.theme.spacing';
import { useLinkTo } from '~/navigation/router';
import { useListener } from '@unboared/lib';
import { Button } from '@unboared/base-ui.buttons.button';
import { motion } from 'framer-motion/dist/framer-motion';

export type NavbarProps = {
  /**
   * the size of each button in the navbar.
   */
  size?: number,

  /**
   * 
   */
  current?: string,

  /**
   * An optional style override.
   */
  style?: StyleProp<ViewStyle>,
  active: any,
};

const active2name = {
  "home": "Games",
  "search": "Search",
  "settings": "Settings",
}

export const Navbar = ({ active, size = 18, style }: NavbarProps) => {
  const { normalize } = useNormalize();

  const linkTo = useLinkTo()

  const nameActive = active2name[active]

  const goToOtherScenario = (path) => {
    linkTo(path)
  }
  const goToHome = () => { goToOtherScenario("/") }
  const goToSearch = () => { goToOtherScenario("/search") }
  const goToSettings = () => { goToOtherScenario("/settings") }

  useListener("pressUnboaredGames", goToHome);
  useListener("pressUnboaredSearch", goToSearch);
  useListener("pressUnboaredSettings", goToSettings);

  return (
    <Widget style={[styles.navbar, style]}>
        <NavButton active={nameActive === "Games"} icon="gamepad" tx="common.navigation.games" size={size} style={{ marginHorizontal: normalize(space.tiny) }} onPress={goToHome} />
      <NavButton active={nameActive === "Search"} icon="search" tx="common.navigation.search" size={size} style={{ marginHorizontal: normalize(space.tiny) }} onPress={goToSearch} />
      <NavButton active={nameActive === "Settings"} icon="settings" tx="common.navigation.settings" size={size} style={{ marginHorizontal: normalize(space.tiny) }} onPress={goToSettings} />
    </Widget>
  );
}

export type NavbuttonProps = {
  /**
   * the icon
   */
  icon: string,

  /**
   * the text
   */
  tx: string,

  /**
   * the size
   */
  size: number,

  /**
   * a custom style for the buttons.
   */
  style?: StyleProp<ViewStyle>,

  /**
   * action to execute when pressed
   */
  onPress: any,

  /**
   * if this is the current 
   */
  active: boolean,
};

const NavButton = ({ active, icon, tx, size, onPress, ...rest }: NavbuttonProps) => {
  let button;
  if (active) {
    button = <Button {...rest} textPreset="large" icon={icon} tx={tx} onPress={onPress} />
  }
  else {
    button = <Button {...rest} textPreset="large" icon={icon} preset="secondary" onPress={onPress} />
  }
  return button
}

const styles = StyleSheet.create({
  navbar: {
    position: 'absolute',
    zIndex: 99,
  }
});
