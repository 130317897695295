import React, { useEffect } from "react";
import { View, ViewStyle } from "react-native";

import { useTheme } from '@unboared/utils.theme'
import { useLanguage } from '@unboared/utils.language';
import { Switch, Text, useNormalize } from '@unboared/base-ui.all';

/**
 * !!! Development only !!!
 * 
 * This component allows developers to access the configurations of the platform
 * easily in order to test their work in every context. 
 * 
 */
export const DevConfiguration = () => {
    const { theme, toggleDarkTheme } = useTheme()
    const { language, setLanguage, toggleLanguage } = useLanguage()
    const { normalize } = useNormalize();

    const styles = {
        container: {
            position: 'absolute',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'flex-end',
            bottom: normalize(10),
            right: normalize(10),
            zIndex: 100
        } as ViewStyle,
        config: {
            flexDirection: 'row',
            marginBottom: normalize(5)
        } as ViewStyle
    }

    return (
        <View style={styles.container}>
            <ToggleSetting text={theme.mode} value={theme.mode == 'light'} toggle={toggleDarkTheme} />
            <ToggleSetting text={language} value={language === 'en'} toggle={toggleLanguage} />
        </View>
    )
}

const ToggleSetting = ({ text, value, toggle }: any) => {
    const { normalize } = useNormalize();

    const styles = {
        config: {
            flexDirection: 'row',
            marginBottom: normalize(5)
        } as ViewStyle
    }
    return (
        <View style={styles.config}>
            <Text preset="light" text={text} />
            <Switch checked={value} toggle={toggle} size={10} />
        </View>
    )
}