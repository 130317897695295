import React from 'react';
import { Platform } from 'react-native';
import styled from 'rn-css';

import { useNormalize } from '@unboared/base-ui.all';
import { Widget } from '@unboared/design.all';

import { ConnexionButton } from '~/components/common/system_actions/connexion';
import { MuteButton } from '~/components/common/system_actions/volume';
import { FullscreenButton } from '~/components/common/system_actions/fullscreen';

export type SystemActionsWidgetProps = {
  /**
   * a size of each system action button.
   */
  size?: number
};

const ButtonView = styled.View<{ first?: boolean, marginSize: number }>`
  margin-left : ${props => props.marginSize} ;
`

export const SystemActionsWidget = ({ size = 20 }: SystemActionsWidgetProps) => {
  const { normalize } = useNormalize()

  const system_action_widget = Platform.select({
    native: (
      <Widget size={size}>
        <ButtonView marginSize={0}>
          <ButtonView marginSize={normalize(size / 3)}>
            <MuteButton size={size} />
          </ButtonView>
          <ConnexionButton size={size} />
        </ButtonView>
      </Widget>
    ),
    default: (
      <Widget size={size}>
        <ButtonView marginSize={0}>
          <ConnexionButton size={size} />
        </ButtonView>
        <ButtonView marginSize={normalize(size / 3)}>
          <MuteButton size={size} />
        </ButtonView>
        <ButtonView marginSize={normalize(size / 3)}>
          <FullscreenButton size={size} />
        </ButtonView>
      </Widget>
    )
  })

  return system_action_widget;
}
